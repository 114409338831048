import format from "date-fns/format";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import download from "downloadjs";


export default {



  kitchenOpen(menu, currentHM) {
    //  console.log([this.$store.state.currentHM, this.$store.state.currentHM < "02:00"])

    if (menu?.options?.length > 0) {
      return false
    }

    let closingTime = menu?.kitchenCloses ?? process.env.VUE_APP_KITCHEN_CLOSES ?? "00"

    return currentHM < closingTime
  },

  currentDomain() {
    return this.$store.state.currentDomain;
  },

  getOptionDescription(o) {
    let bits = o.split("#")
    return bits[0]
  },

  getOptionAllergens(o) {
    let bits = o.split("#")
    if (!bits[1]) {
      return []
    }
    bits = bits[1].split(",")
    return bits
  },

  getFontSizeToFit(text, font, width, tmpfontSize) {

    let nameWidth

    do {
      nameWidth = font.widthOfTextAtSize(text, tmpfontSize);


      if (nameWidth > width) {
        tmpfontSize *= 0.95
      }

    }
    while (nameWidth > width)

    return tmpfontSize
  },

  centreText(page, text, y, margin, font, tmpfontSize) {

    const { width, } = page.getSize();

    //console.log(height)

    //console.log(text, width, tmpfontSize, margin, width - (2 * margin))

    tmpfontSize = this.getFontSizeToFit(text, font, width - (2 * margin), tmpfontSize)

    //console.log(text, width, tmpfontSize, margin, width - (2 * margin))


    let twidth = font.widthOfTextAtSize(text, tmpfontSize);

    //console.log(text, width, tmpfontSize, margin, width - (2 * margin), twidth, ((width - twidth - margin) / 2))

    page.drawText(text, {
      x: ((width - twidth) / 2),
      y: y - tmpfontSize,
      size: tmpfontSize,
      font: font,
      color: rgb(0, 0, 0),
    });


  },





  async printSlips(orders, date, menu) {
    const pdfDoc = await PDFDocument.create();
    const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);


    let allergensImg = {}
    for (let option of orders) {


      const page = pdfDoc.addPage([62, 100]);
      const { width, height } = page.getSize();
      const title = option[0].toUpperCase();

      page.drawLine({
        start: { x: 2, y: height - 3 },
        end: { x: width - 2, y: height - 3 },
        thickness: 1,
        color: rgb(0, 0, 0),
        opacity: 0.75,
      });

      page.drawLine({
        start: { x: 2, y: 3 },
        end: { x: width - 2, y: 3 },
        thickness: 1,
        color: rgb(0, 0, 0),
        opacity: 0.75,
      });

      page.drawLine({
        start: { x: 2, y: height - 3 },
        end: { x: 2, y: 3 },
        thickness: 1,
        color: rgb(0, 0, 0),
        opacity: 0.75,
      });

      page.drawLine({
        start: { x: width - 2, y: height - 3 },
        end: { x: width - 2, y: 3 },
        thickness: 1,
        color: rgb(0, 0, 0),
        opacity: 0.75,
      });

      let start = height - 3

      this.centreText(page, title, start, 4, timesRomanFont, 10);
      start -= 11
      this.centreText(page, date, start, 3, timesRomanFont, 8);
      start -= 9
      this.centreText(page, option[3].toUpperCase(), 21, 3, timesRomanFont, 6);
      this.centreText(page, option[2].toUpperCase(), 13, 3, timesRomanFont, 8);




      let allergens = {}



      for (let option2 of menu["options"][option[0]]["options"]) {

        let subtitle = option2["title"].toUpperCase()

        this.centreText(page, subtitle, start, 3, timesRomanFont, 6);

        start = start - 6.5

        if (option2.allergen) {
          option2.allergen.forEach(element => {
            allergens[element] = 1
          });
        }

        for (let option3 of option2.choose) {

          console.log([option3])

          let option3a = this.getOptionDescription(option3)
          let o = this.getOptionAllergens(option3)

          console.log([o])





          if (option[1].includes(option2["title"] + "_#_" + option3) || option[1].includes(option2["title"] + "_#_" + option3a)) {

            this.centreText(page, option3a, start, 3, timesRomanFont, 4);

            if (o) {
              o.forEach(element => {
                allergens[element] = 1
              });
            }

            start = start - 4.5

          }
        }
      }



      let z = Object.keys(allergens)
      console.log(z)

      if (z.length > 0) {


        let x = 3 + (58 - (11 * z.length)) / 2

        for (let element of z) {

          console.log(element)

          if (!allergensImg[element]) {
            let res = await fetch(process.env.BASE_URL + "img/icons/allergen/" + element + ".png")
            console.log(res)
            let arrayBuffer = await res.arrayBuffer()
            console.log(arrayBuffer)
            allergensImg[element] = await pdfDoc.embedPng(arrayBuffer)
          }

          page.drawImage(allergensImg[element], { x: x, y: 21, width: 10, height: 10 });
          x = x + 11



        }
      }



    }
    pdfDoc.save().then((blob) => {
      download(blob, "lunchOrder-" + format(new Date(), "yyyy-MM dd HH:mm:ss") + ".pdf", "application/pdf");
    });
  },


  async processSummary(month, summary) {

    summary.unshift(["Class", "Surname", "Firstname", ["Days"], "StudentID"])

    let blob = summary.map(e => {
      e[3] = '"' + e[3].join(",") + '"'
      return e.join(",")
    }).join("\n")
    download(blob, "lunchOrders-" + month + ".csv", "text/csv");

  }

}








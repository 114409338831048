<template>
  <button
    class="media tile is-child box" :disabled=" !enabled" :class="(selectedOption == optionName) ? 'has-background-primary-light' : ''"
    v-on:click="select(null, null)" >

      <div class="content"  style="height: 100%">
        <p class="title">{{ optionName }}</p>
        <div v-for="(option, oIndex) in option.options" :key="oIndex">
          <label>{{ option.title }}</label>
          <ul >
            <li v-for="optionV in option.choose" :key="optionV">
              <label class="checkbox is-flex is-flex-direction-row ">
                <input type="checkbox"
                      @change="select()"
                      :value="option.title+'_#_'+optionV"
                      v-model="selectedOptions"
                      :id="option.title+'_#_'+optionV"
                      :disabled="selectedOptionDisabled(option, option.title+'_#_'+optionV)"
                />
                {{ lunchH.getOptionDescription(optionV) }}  &nbsp;
                <span class="has-text-right is-pulled-right">
                  <figure v-for="allergen in lunchH.getOptionAllegens(optionV)" :key="allergen"  class="image is-16x16 m-0 is-pulled-right">
                    <img :src="publicPath + 'img/icons/allergen/'+allergen+'.png'" :alt="allergen" :title="allergen" />
                  </figure>
                </span>
              </label>
            </li>
          </ul>
        <div class="container">
          <div v-if="option.allergen && option.allergen.length > 0" class="is-flex is-flex-direction-row">
            <figure v-for="allergen in option.allergen" :key="allergen"  class="image is-16x16 m-0 is-pulled-right">
               <img :src="publicPath + 'img/icons/allergen/'+allergen+'.png'" :alt="allergen" :title="allergen" />
            </figure>
          </div>
          <p class="has-text-right has-text-weight-semibold is-italic is-size-7" v-if="option.limit > 0">Choose any {{ option.limit }} item{{ option.limit > 1 ? "s" : "" }}.</p>
          </div>
          <hr />
        </div>
      </div>

  </button>
</template>

<script>
//import firebase from 'firebase/compat/app';
//import 'firebase/compat/auth';

//import { format } from "date-fns";
//import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
//import download from "downloadjs";
import lunch from "helpers/lunch"

export default {
  name: "LunchOption",

  data: function () {
    return {
      students: [],
      menu: [],
      lastUpdate: "",
      day: "",
      selectedOptions:[],
      publicPath: process.env.BASE_URL,

    };
  },
  props: ["option", "optionName", "selectedOption", "previousOptions", "enabled"],

  watch: {
    selectedOption: function(a) {

      //console.log([a,  this.selectedOptionsEnabled, this.enabled , "in" ])
      if (a == this.optionName) {
        //console.log([a,  this.selectedOptionsEnabled, this.enabled , "out" ])
        this.selectedOptionsEnabled = this.selectedOptions
        return;
      }
    //  console.log(this.selectedOptions)

      this.selectedOptions = [];
      this.selectedOptionsEnabled = [];
      //console.log([a,  this.selectedOptionsEnabled, this.enabled, "out"  ])




    }

  },


  computed: {


    lunchH() {
      return lunch;
    },



  },
  methods: {




    select() {
      //console.log(this.option);

      this.$emit("option-selected", this.optionName, this.selectedOptions);

    },
    selectedOptionDisabled(option, link) {

      if (this.selectedOption != this.optionName || !this.enabled ) {
        return true;
      }



        //console.log([option, link])

        if (option.limit == 0 ) {
          return false;
        }

        if (this.selectedOptions.includes(link)) {
          return false;
        }

        if (this.selectedOptions.filter(x => x.startsWith(option.title+"_#_")).length < option.limit) {
          return false;
        }



        return true;

      }
  },
  mounted() {
    if (this.selectedOption == this.optionName) {
      this.selectedOptions = this.previousOptions;
      this.selectedOptionsEnabled = this.previousOptions
    }

  },
  beforeMount() {

  }
};
</script>

<template>
  <div class="container has-background-white-ter">
    <section class="section is-primary pb-1">
      <div class="container">
        <div class="columns">
          <div class="column is-8-desktop is-offset-2-desktop">
            <h1 class="title is-2 is-spaced">
              Order My Lunch
              <span class="is-small">{{ day }}</span>
            </h1>
          </div>
        </div>
      </div>
    </section>
    <section id="users-timetable" class="section">
      <div class="container">
        <div class="columns">
          <div v-if="menu" class="column is-8-desktop is-offset-2-desktop">
            <div class="tile is-ancestor p">
              <div
                v-for="(option, optionName) in menu.options"
                :key="optionName"
                class="tile is-parent"
              >

                <LunchOption
                  @option-selected="optionSelected"
                  v-bind:option="option"
                  v-bind:selectedOption="selectedOption"
                  v-bind:previousOptions="selectedOptions"
                  v-bind:optionName="optionName"
                  v-bind:enabled="enabled"
                ></LunchOption>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container has-text-centered">
        <p class="is-size-4">FOOD ALLERGIES and INTOLERANCES</p>

        <p class="is-size-6">
          Please speak to our staff about the ingredients in your meal, when
          making your order.
        </p>
      </div>
      <div v-if="enabled" class="box has-text-centered">
        <button
          class="button"
          :disabled="selectedOption == null || !enabled"
          @click="place()"
        >
          Place Order
        </button>
        <button
          class="button is-danger"
          :disabled="placed == null || !enabled"
          @click="cancel()"
        >
          Cancel Order
        </button>
      </div>
    </section>
  </div>
</template>

<script>
//import firebase from 'firebase/compat/app';
//import 'firebase/compat/auth';

import { toast } from "bulma-toast";
import lunchHelper from "helpers/lunch"
//import { format } from "date-fns";
//import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
//import download from "downloadjs";
import LunchOption from "./LunchOption.vue";

export default {
  name: "home",

  components: {
    LunchOption,
  },

  data: function () {
    return {
      students: [],
      menu: [],
      lastUpdate: "",
      day: "",
      selectedOption: null,
      selectedOptions: {},
      placed: null,
    };
  },

  computed: {


    enabled() {
      //  console.log([this.$store.state.currentHM, this.$store.state.currentHM < "02:00"])
      return lunchHelper.kitchenOpen(this.menu, this.$store.state.currentHM)
    },

    currentDomain() {
      return this.$store.state.currentDomain;
    },
  },
  methods: {
    optionSelected(event, b) {
      this.selectedOption = event;
      this.selectedOptions = b;
    },

    async cancel() {
      let t = await this.$api.post("v2/lunch/today/", true, true, []);

      if (t.status == "ok") {
        this.placed = null;
        this.selectedOption = null;
        this.selectedOptions = [];

        toast({
          message: "Order cancelled",
          type: "is-success",
          dismissible: true,
          pauseOnHover: true,
          position: "center",
        });
        this.$router.push("/home/");
      } else {
        toast({
          message: "Sorry, something went wrong, please try again",
          type: "is-danger",
          dismissible: true,
          pauseOnHover: true,
          position: "center",
        });
      }
    },

    async place() {
      let t = await this.$api.post("v2/lunch/today/", true, true, [
        this.selectedOption,
        this.selectedOptions,
      ]);

      if (t.status == "ok") {
        this.placed = t.placed;
        toast({
          message: "Order placed",
          type: "is-success",
          dismissible: true,
          pauseOnHover: true,
          position: "center",
        });
        this.$router.push("/home/");
      } else {
        toast({
          message: "Sorry, something went wrong, please try again",
          type: "is-danger",
          dismissible: true,
          pauseOnHover: true,
          position: "center",
        });
      }
    },
  },

  async mounted() {
    this.$api.get("v2/lunch/today/", true, true).then((response) => {
      this.menu = response[0];
      if (response[1]) {
        this.selectedOption = response[1][0];
        this.selectedOptions = response[1][1];
        this.placed = true;
      }
    });

    //this.loadRegister();
  },
};
</script>
